import React from 'react'
import axios from 'axios'
import Header from '../Header/Header';
import { url } from '../../utils/const';

class ChangePassword extends React.Component {


    constructor(props) {
      super(props);
      this.state = {password: '', newPassword: '', repeatedPassword: '', error:  '', success: ''};

      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleChange(e) {
        this.setState({ [e.target.name] : e.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();

        const passwordChange = {
            currentPassword: this.state.password,
            newPassword: this.state.newPassword,
            repeatedNewPassword: this.state.repeatedPassword
        }

        axios.post(url + '/api/v1/secure/change-password/', passwordChange).then(response => {
            this.setState({success: "Het wachtwoord is succesvol", error: '', password: '', newPassword: '', repeatedPassword: ''})
        })
        .catch(error => {
            this.setState({error: error.response.data, success: '', password: '', newPassword: '', repeatedPassword: ''})
        });

    }

    render() {
        return (
            <div>
                <Header location={this.props.location}/>
                <main className="main-content m-t-xs responsive-form">
                    <div className="container">
                        <div className="card small-padding m-t-42">
                            <div className="message error">{this.state.error}</div>
                            <div className="message success">{this.state.success}</div>
                            <form method="post" onSubmit={this.handleSubmit}>
                                <div className="col-3 float-left same-height-52 p-xs">
                                    <label className="block">Huidige wachtwoord:</label>
                                    <input type="password" name="password" value={this.state.password} onChange={this.handleChange}/>
                                </div>
                                <div className="col-3 float-left same-height-52 p-xs">
                                    <label className="block">Nieuw wachtwoord:</label>
                                    <input type="password" name="newPassword" value={this.state.newPassword} onChange={this.handleChange}/>
                                </div>
                                <div className="col-3 float-left same-height-52 p-xs">
                                    <label className="block">Herhaal wachtwoord:</label>
                                    <input type="password" name="repeatedPassword" value={this.state.repeatedPassword} onChange={this.handleChange}/>
                                </div>
                                <div className="col-3 float-left same-height-52 p-xs">
                                    <label className="block">&nbsp;</label>
                                    <button className="full-width btn btn-info" onClick={this.handleSubmit}>Wijzigen</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}
export default ChangePassword;
