import React from "react";
import Modal from 'react-modal';

class ProjectCellDetailModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modalIsOpen: false
        };

        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    openModal() {
        this.setState({modalIsOpen: true});
    }

    afterOpenModal() {
    }

    closeModal() {
        this.setState({modalIsOpen: false});
        this.props.onSubmit();
    }

    onChange(event) {
        this.props.onChange(event.target.value);
    }

    render() {
        let attr = {};
        if (this.props.immutable) {
            attr = {'disabled': true}
        }

        let modalOrNot = null;
        if (this.state.modalIsOpen) {
            modalOrNot = (<Modal isOpen={this.state.modalIsOpen}
                                onAfterOpen={this.afterOpenModal}
                                onRequestClose={this.closeModal}
                                className="modal"
                                overlayClassName="modal-overlay"
                                ariaHideApp={false}>

                            <h1>Opmerking</h1>
                            <textarea className="modal-input"
                                    autoFocus={true}
                                    onChange={this.onChange}
                                    value={this.props.value}
                                    {...attr}/>
                            <div className="float-right m-t-sm">
                                <button className="btn btn-primary" onClick={this.closeModal}>Sluit</button>
                            </div>
                        </Modal>);
        }

        const contiansCommentClass = (this.props.value == null || this.props.value === "") ? "contians-no-comment" : "contians-comment";        
        
        return (
            <div className={"modal-button-container " + contiansCommentClass}>
                <button className="btn-open-modal" onClick={this.openModal}><i className="white fa fa-info"/></button>
                {modalOrNot}
            </div>
        );
    }

} export default ProjectCellDetailModal;
