import React from 'react'
import axios from 'axios'
import Header from '../Header/Header';
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import 'moment/locale/nl';
import { url } from '../../utils/const';

class Holiday extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        axios.get(url + `/api/v1/secure/holidays`).then(res => {
            this.setState({
                holidays: res.data.holidays,  
                remainingDaysOf: res.data.numberOfDaysOff != null ? res.data.numberOfDaysOff : 0 ,
                pendingDays: res.data.numberOfDaysPending != null ? res.data.numberOfDaysPending : 0
            });
        });
    }

    render() {
        moment.updateLocale('nl', {});
        let holidays = null;
        let states = {"PENDING": "Afwachtend", "ACCEPTED": "Geaccepteerd", "DISCARDED": "Afgewezen", "INVOICED": "Gefactureerd"};
        if (this.state.holidays != null) {
            holidays = this.state.holidays.map(holiday => {
                let evaluatedAt;
                if (holiday.evaluatedAt != null) evaluatedAt = moment(holiday.evaluatedAt).format("DD-MM-YYYY")

                return (
                    <tr key={holiday.requestedAt}>
                        <td>{moment(holiday.requestedAt).format("DD-MM-YYYY")}</td>
                        <td>{moment(holiday.fromDate).format("DD-MM-YYYY")}</td>
                        <td>{moment(holiday.untilDate).format("DD-MM-YYYY")}</td>
                        <td>{holiday.offTime}</td>
                        <td>{states[holiday.state]}</td>
                        <td>{evaluatedAt}</td>
                    </tr>
                )
            });
        }

        return (
            <div>
                <Header location={this.props.location} />
                <main className="main-content m-t-xs responsive-form">
                    <div className="container">
                        <div className="card small-padding m-t-42">
                            <h2 className="h1 m-t-sm m-b-sm">Mijn vakanties</h2>
                            Beschikbaar:  {this.state.remainingDaysOf} <br/>
                            Aangevraagd:  {this.state.pendingDays}
                            <h3 className="m-t-sm">Hoe vraag ik vakantie aan?</h3>
                            Op de homepagina boek je de uren op het vakantieproject. <br/>
                            Zodra de leidinggevende de aanvraag heeft goed-/afgekeurd, krijg je een e-mail.
                            <div className="clearFix"/>
                            <div className="has-wide-table m-t-sm">
                                <table className="full-width wide-table table">
                                    <thead>
                                        <tr>
                                            <th>Aangevraagd op</th>
                                            <th>Van</th>
                                            <th>Tot</th>
                                            <th>Aantal dagen</th>
                                            <th>Status</th>
                                            <th>Beoordeeld op</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {holidays}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}
export default Holiday;
