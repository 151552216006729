import React from 'react'
import axios from 'axios'
import { url, maxActivityLength } from '../../utils/const';
import Project from './Project';
import { toast } from 'react-toastify';
import moment from "moment";
import Modal from 'react-modal';
import Countdown from 'react-countdown-now';


class List extends React.Component {

    constructor(props) {        
        super(props);    
        this.state = {projects: [], time: null};
    }

    componentDidMount() {        
        this.setState({activity: { project: this.props.user.project, start: this.props.user.start}, date: null, time: null});
        this.updateActiveProject();    
    }

    render() {
        return (
           <div>          
                <h1 className="float-left">Hallo {this.props.user.firstName}</h1>                                       
                <button className="float-right btn btn-info m-l-xs" onClick={this.props.logout.bind(this)}>Uitloggen</button>                
                {this.getCheckOutButton()}
                <div className="clearFix"></div>
                {this.getActivityTemplate()}
                {this.getProjectsTemplate()}
                {this.getLogoutModal()}
                {this.getCheckoutModal()}
           </div>
        )
    }

    updateActiveProject() {        
        const parent = this;        
        axios.post(url + '/api/v1/projects', this.props.user.authenticationToken, { headers: { 'Content-Type': 'text/plain' } }).then(response => {                                    
            let start = new Date(this.state.activity.start), end = new Date();                
            let d = null;
            let t = null;
            if (this.state.activity.start !== null && (end.getTime() - start.getTime()) / (1000 * 60 * 60) > maxActivityLength) {  
                d = moment(new Date(this.props.user.start)).format("DD-MM-YYYY");
                t = moment(new Date(this.props.user.start)).add(10, 'minutes').format("HH:mm");                
            }
            parent.setState({ projects: response.data, date: d, time: t });
        });
    }

    activateProject(projectId) {
        if (this.hasActivity()) {            
            this.setState({checkOutModal: true, newProjectId: projectId});
        } else {
            this.activateProjectCall(projectId);
        }
    }

    activateProjectCall(projectId) {
        const params = {projectId: projectId, token: this.props.user.authenticationToken, start: new Date()}        
        axios.post(url + "/api/v1/activity", params).then(response => {            
            toast.info("Je bent succesvol ingecheckt!");            
            this.setState({activity: { project: response.data.project, start: response.data.start}, logoutModal: true});
        });
    }

    hasActivity() {             
        return this.state.activity !== undefined && this.state.activity.project !== null && this.state.activity.start !== null;
    }

    cancelLogoutModal() {
        this.setState({logoutModal: false});
    }

    checkOut() {                
        let date = new Date()
        if (this.state.date !== null && this.state.time !== null) {
            date = moment(this.state.date + " " + this.state.time, "DD-MM-YYYY HH:mm").toDate();                        
        }

        const params = {token: this.props.user.authenticationToken, description: this.state.description, end: date }
        if (this.state.newProjectId !== null) {
            params.newProjectId = this.state.newProjectId;
        }        
        axios.post(url + "/api/v1/activity/stop", params).then(response => {            
            toast.info("Je bent succesvol uitgecheckt!");            
            let activity = {project: null, start: null};
            if (response.data.project !== null && response.data.start != null) {
                activity = { project: response.data.project, start: response.data.start };
            }
            this.setState({logoutModal: true, checkOutModal: false, activity: activity});
        });
    }

    /* 
     * CHANGE HANDLERS
    ---------------------------------------------------------------------------- */
    handleDescriptionChange(event) { this.setState({description: event.target.value}); }
    handleTimeChange(event) { this.setState({time: event.target.value}); }
    handleDateChange(event) { this.setState({date: event.target.value}); }

    /* 
     * TEMPLATING METHODS
    ---------------------------------------------------------------------------- */
    getActivityTemplate() {
        var activity = (<p>U bent niet actief</p>);        
        if (this.hasActivity()) {            
            let start = '';
            if (moment(this.state.activity.start).format("DD-MM-YYYY") === moment(new Date()).format("DD-MM-YYYY")) {
                start = moment(this.state.activity.start).format("HH:mm");
            } else {
                start = moment(this.state.activity.start).format("DD-MM-YYYY HH:mm");
            }
            activity = (
                <p>
                    Start: <b className="strong">{start}</b><br/>
                    Activiteit: <b className="strong">{this.state.activity.project.name}</b><br/> 
                    {this.state.activity.project.category !== null ? (<span>Project: <b className="strong">{this.state.activity.project.category}</b><br/></span>) : ""}
                    Klant: <b className="strong">{this.state.activity.project.customer.name}</b>
                </p>
            )
        }
        return activity;
    }

    getProjectsTemplate() {
        let projects = "";
        if (this.state.projects.length > 0) {
            let currentCategory = null;
            let currentCustomer = null;            
            projects = this.state.projects.map(project => {            
                const showCustomer = project.customer.name !== currentCustomer;
                const showCategory = project.customer.name !== currentCustomer || (project.customer.name === currentCustomer && project.category !== currentCategory);
                currentCategory = project.category;
                currentCustomer = project.customer.name;                                
                return (<Project project={project} 
                                key={project.id}
                                showCategoryHeader={showCategory}
                                showCustomerHeader={showCustomer}
                                activity={this.props.user.activity}
                                activateProject={this.activateProject.bind(this)}>{project.name}</Project>)
            });
        }
        return projects;
    } 

    getLogoutModal() {
        let logoutModal = "";        
        if (this.state.logoutModal) {            
            logoutModal = (
                <Modal onRequestClose={this.props.logout.bind(this)}                      
                       contentLabel="Uitloggen"
                       ariaHideApp={false}
                       isOpen={true}
                       className="modal"
                       overlayClassName="modal-overlay">
                    <h1>
                        Je wordt na 
                        <Countdown date={Date.now() + 5000} 
                                    onComplete={this.props.logout.bind(this)}
                                    renderer={props => <span> {props.seconds} </span>}/>
                        seconden automatisch uitgelogd.
                    </h1>
                    <button className="btn btn-info m-t" onClick={this.cancelLogoutModal.bind(this)}>Annuleer</button>
                    <button className="btn btn-info m-t m-l-xs" onClick={this.props.logout.bind(this)}>Log je nu uit!</button>
                </Modal>
            );
        }
        return logoutModal;
    }

    getCheckoutModal() {
        let checkOutModal = "";
        if (this.state.checkOutModal) {
            checkOutModal = (
                <Modal onRequestClose={() => this.setState({checkOutModal: false})}                      
                       contentLabel="Check uit"
                       ariaHideApp={false}
                       isOpen={true}
                       className="modal"
                       overlayClassName="modal-overlay">
                    <h1>Uitchecken</h1>                    
                    {this.getErrorForm()}
                    <textarea id="DescriptionTextArea"
                              className="modal-input"
                              autoFocus={true}
                              placeholder="Vul eventueel een opmerking in (optioneel)..."     
                              onChange={this.handleDescriptionChange.bind(this)}        
                              value={this.state.description}></textarea>            
                    <button className="btn btn-info m-t-sm m-r-xs" onClick={() => this.setState({checkOutModal: false})}>Annuleer</button>
                    <button className="btn btn-info m-t-sm" onClick={this.checkOut.bind(this)}>Opslaan</button>
                </Modal>
            );
        }
        return checkOutModal;
    }

    getErrorForm() {
        let errorForm = "";
        let start = new Date(this.state.activity.start), end = new Date();                
        if ((end.getTime() - start.getTime()) / (1000 * 60 * 60) > maxActivityLength) {            
            // creates a list of days
            let days = [];            
            if (moment(start).format("DD-MM-YYYY") !==  moment(end).format("DD-MM-YYYY")) {
                let done = false;
                while (!done) { // ugh fixme
                    done = moment(start).format("DD-MM-YYYY") ===  moment(end).format("DD-MM-YYYY");
                    let str = moment(start).format("DD-MM-YYYY");
                    days.push(<option key={str} value={str}>{str}</option>);
                    start = moment(start).add(1, 'days');                                
                }
            }   
            errorForm = (
                <div className="m-t-xs">
                    <p>Het lijkt erop dat je de vorige keer vergeten bent uit te checken, vul hieronder het correcte eindmoment in.</p>
                    <div className="m-t-sm col-6 float-left p-r-xs">
                        <label className="block">Kies datum:</label>
                        <select className="modal-input-small full-width" value={this.state.date} onChange={this.handleDateChange.bind(this)}>
                            {days}
                        </select>
                        </div>
                    <div className="m-t-sm col-6 float-left p-l-xs">
                        <label className="block">Tijd:</label>
                        <input type="time" className="modal-input-small full-width" value={this.state.time} onChange={this.handleTimeChange.bind(this)}/>
                    </div>
                    <div className="clearFix"></div>
                </div>
            );
        }
        return errorForm;
    }

    getCheckOutButton() {        
        return this.hasActivity() ? (<button className="float-right btn btn-info" onClick={()=> this.setState({checkOutModal: true})}>Uitchecken</button>) : "";
    }
}
export default List;
