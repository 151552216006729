import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { MemoryRouter, Route } from 'react-router-dom';

// import registerServiceWorker from './registerServiceWorker';
import store from './store.js';
import Layout from "./components/Layout/Layout";
import setAuthorizationToken from "./utils/setAuthorizationToken";
import Login from "./components/Login/Login";
import Holiday from "./components/Holiday/Holiday";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import Scan from "./components/Nfc/Scan";
import Switch from "react-router-dom/es/Switch";
import requireAuth from "./requireAuth";
import {setCurrentUser} from "./actions/AuthActions";


import jwt from 'jsonwebtoken';


if (localStorage.jwtToken) {
    setAuthorizationToken(localStorage.jwtToken);
    store.dispatch(setCurrentUser(jwt.decode(localStorage.jwtToken)));
}

ReactDOM.render(
    <Provider store={store}>
        <MemoryRouter>
            <Switch>
                <Route path="/login" component={Login}/>
                <Route path="/scan" component={Scan}/>
                <Route path="/holidays" component={requireAuth(Holiday)}/>            
                <Route path="/change-password" component={requireAuth(ChangePassword)}/>
                <Route path="/holiday-overview" component={requireAuth(Layout)}/>
                <Route path="" component={requireAuth(Layout)}/>
            </Switch>
        </MemoryRouter>        
    </Provider>,
    document.getElementById('root')
);
// registerServiceWorker();
