import React from 'react';

class HeaderItem extends React.Component {

    componentWillMount() {
        const now  = new Date();
        const date = this.props.date.date;    

        if (now.getDate()     === date.getDate() &&
            now.getMonth()    === date.getMonth() &&
            now.getFullYear() === date.getFullYear()) {
            this.setState({today:true});
        } else {
            this.setState({today:false});
        }
    }

    render() {

        let classes = [];
        if (this.state.today) {
            classes.push("today ");
        }

        // todo i18n
        const days      = ['zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za', ];
        const months    = ['jan.', 'feb.', 'maart', 'apr.', 'mei', 'juni', 'juli', 'aug.', 'sept.', 'oct.', 'nov.', 'dec.'];
        const date = this.props.date.date;
        const total = this.props.date.total;
        return (
            <div className={classes.join(" ")}>
                <div>{days[date.getDay()]}</div>
                <div>{date.getDate()} {months[date.getMonth()]}</div>
                <div className="strong grey-color">{total}</div>
            </div>
        )
    }
}

export default HeaderItem;
