import React from 'react'
import { connect } from 'react-redux'
import { login } from "../../actions/AuthActions";
import { Redirect } from 'react-router-dom';
import {url } from '../../utils/const';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            error: false,
            isLoading: false
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    isValid() {
       if (this.state.email === "" || this.state.password === "") {
           toast.error('Vul het formulier in om in te loggen.', { draggablePercent: 60 });
       } else {
           return true;
       }
    }

    onSubmit(e) {
        e.preventDefault();
        if (this.isValid()) {
            this.setState({isLoading: true });
            this.props.login(this.state).then(
                (res) => this.setState({redirect: "/"}),
                (err) => {
                    this.setState({ isLoading: false })
                    toast.error('Het ingevoerde wachtwoord is onjuist.', { draggablePercent: 60 });
                }
            );
        }
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        if (this.state.redirect === "Scan") return (<Redirect to="scan" />);
        if (this.state.redirect === "/") return (<Redirect to="/" />);
        
        
        return (
            <div className="login-page">
                <header className="login-header"/>
                <div className="login-container">
                    <div className="login-logo"> </div>
                    <div className="card">
                        <form onSubmit={this.onSubmit}>                            
                            <div className="m-b-sm">
                                <label htmlFor="username">Email</label>
                                <input type='text' name='email' onChange={this.onChange} id="username"/>
                            </div>
                            <div className="m-b-sm">
                                <label htmlFor="password">Wachtwoord</label>
                                <input type="password" name="password" onChange={this.onChange}  id="password"/>
                            </div>
                            <button className="btn btn-primary full-width m-t-xs" name="submit" type="submit">Inloggen</button>
                            <a className="inline-block subtle m-t-xs hover-underline-animation" href={url + '/login/request-password'}>Ben je nieuw of ben je je wachtwoord vergeten?</a>
                            
                        </form>
                    </div>
                    <div className="block m-l subtle m-t-sm hover-underline-animation clickable" onClick={this.openNfc.bind(this)}>Log in met NFC!</div>                            
                </div>
                <ToastContainer />
            </div>
        )
    }

    openNfc() {
        this.setState({redirect: "Scan"});
    }

}

export default connect(null, { login })(Login);
