import { combineReducers } from 'redux';
import customerReducer from './CustomerReducer';
import authReducer from './AuthReducer';
import userReducer from './UserReducer';

export default combineReducers({
    customers: customerReducer,
    auth: authReducer,
    user: userReducer,
});
