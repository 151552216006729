import React from 'react'

class Search extends React.Component {

    render() {
        let position = "show-on-mobile search";//;this.props.position;
        return (
            <input className={position}
                   onChange={this.props.search}
                   type="search"
                   placeholder={"Zoek..."}/>
        )
    }
}

export default Search;