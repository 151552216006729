import React from 'react';
import ProjectCell from "./ProjectCell";

class ProjectRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {unknownOffTime: false};
    }

    static formatDate(date) {
        let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    render() {
        let name = this.props.project.name;
        let workSessions = this.props.project.workSessions;
        const archived = this.props.project.archived;

        let cells = this.props.days.map(date => {
            let foundWorkSessions = null;
            const isoDate = ProjectRow.formatDate(date);
            const length = workSessions != null ? workSessions.length : 0;
            for (let i = 0; i < length; i++) {
                if (workSessions[i].date === isoDate) {
                    foundWorkSessions = workSessions[i];
                }
            }

            // on the weekends {saturday === 6, sunday === 0}
            // @todo free-day api(?) so it will display days like xmas differently as well (COULD HAVE)
            const freeDay = date.getDay() === 6 || date.getDay() === 0;
            return (
                <ProjectCell key={isoDate}
                             archived={archived}
                             date={isoDate}
                             workSessions={foundWorkSessions}
                             freeDay={freeDay}
                             updateHolidaySaldo={this.updateHolidaySaldo.bind(this)}
                             customer={this.props.customer}
                             project={this.props.project}/>
            )
        });

        let classes = 'project-row first';
        if (archived) classes = classes + " archived"

        if (this.props.project.category !== null && this.props.project.category !== '') {
            classes = classes + " has-category ";
        }

        let title = "";
        if (this.props.displayCategoryTitle && this.props.project.category != null) {
            title = (<section className="project-category">{this.props.project.category}</section>)
            classes = classes + " includes-header"
        }


        let statSpan;
        let total = "";
        if (this.props.stats.total !== 0) {
            total = " (" + this.props.stats.total + ")";
            statSpan = (<span className="float-right strong grey-color p-r-xs">{this.props.stats.total}</span>)
        }

        let budget;
        if (this.props.project.budget !== 0) {
            const color = (this.props.project.bookedHours + this.props.stats.total <= this.props.project.budget)
                ? 'success-color'
                : 'error-color'
            budget = (
                <span className={"float-right strong p-l-xs " + color}>
                        {this.props.project.bookedHours + this.props.stats.total}
                        &nbsp;/&nbsp;
                        {this.props.project.budget}
              </span>
            );
        }

        return (
            <div className={classes}>
                {title}
                <div className="offset" title={name + total}>
                    {name}
                    {budget}
                    {statSpan}
                </div>
                <span className="clear-on-mobile"/>
                {cells}
            </div>
        )
    }

    updateHolidaySaldo() {
        if (this.props.project.holiday) this.props.updateHolidaySaldo();
    }
}

export default ProjectRow;
