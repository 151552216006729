import React from 'react'
import Search from "../Search/Search";
import Menu from "../Menu/Menu";

class Header extends React.Component {

    render() {
        let search
        if (this.props.search != null) {
            search = <Search search={this.props.search} position={"show-on-mobile search"}/>
        }

        return (
            <header className="main-header">
                <div className="container full-height">
                    <a className="logo" href="/" title="Navigeert naar de home-pagina"><span className="hidden">Uren Boeken</span></a>
                    {search}
                    <Menu location={this.props.location}/>
                </div>
            </header>
        )
    }
}

export default Header;
