import React from 'react'
import InfiniteCalendar from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css'; // Make sure to import the default stylesheet

class Navigation extends React.Component {

    constructor(props){
        super(props);
        this.state = { showDatePicker: false };
    }

    render() {

        let datePicker;
        if (this.state.showDatePicker) {
            datePicker = (// Example: Set the first day of the week to Monday
                <InfiniteCalendar width={300}
                                  height={500}
                                  onSelect={this.datePick.bind(this)}
                                  displayOptions={{showHeader: false}}
                                  theme={{
                                        selectionColor: '#005795',
                                        textColor: {
                                          default: '#333',
                                          active: '#FFF'
                                        },
                                        weekdayColor: '#005795',
                                        headerColor: '#005795',
                                        floatingNav: {
                                          background: '#005795',
                                          color: '#FFF',
                                          chevron: '#005795'
                                        }
                                    }}
                                    locale={{
                                        weekStartsOn: 1,
                                        locale: require('date-fns/locale/nl'),
                                        headerFormat: 'dddd, D MMM',
                                        weekdays: ["Zo", "Ma","Di","Wo","Do","Vr","Za"],
                                        blank: 'Geen datum geselecteerd',
                                        todayLabel: {
                                            long: 'Vandaag',
                                            short: 'Nu.'
                                        }
                                    }}/>
            );
        }

        return (
            <div>
                <div className="button-group">
                    <div><button title="Navigeert naar de voorgaande week" onClick={this.props.previousWeek.bind(this)}><i className="fal fa-arrow-alt-to-left"></i></button></div>
                    <div><button title="Toont/Verbergt het weekend" onClick={this.props.toggleWeekend.bind(this)}><i className="fal fa-bed"></i></button></div>
                    <div><button title="Navigeert naar de huidige week" onClick={this.props.now.bind(this)} className="small">Nu</button></div>
                    <div><button title="Toont een kalender waarmee naar een specifieke week genavigeerd kan worden" onClick={this.toggleDatePicker.bind(this)}><i className="fal fa-calendar-alt"></i></button></div>
                    <div><button title="Navigeert naar de volgende week" onClick={this.props.nextWeek.bind(this)}><i className="fal fa-arrow-alt-to-right"></i></button></div>
                </div>
                <div className="date-picker-container">
                    {datePicker}
                </div>
            </div>
        )

    }

    toggleDatePicker() {
        this.setState((prevState) => {
            return {showDatePicker: !prevState.showDatePicker}
        });
    }

    datePick(date){
        this.toggleDatePicker();
        this.props.datePick(date);
    }

}

export default Navigation;
