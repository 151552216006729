import React from 'react'
import axios from 'axios'
import { url } from '../../utils/const';
import List from './List';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DotLoader } from 'react-spinners'; // just pick one https://www.react-spinners.com/
import { Redirect } from 'react-router-dom';


class Scan extends React.Component {

    constructor(props) {
        super(props);                
        this.state = {user: null, isLoading: false};
        const parent = this;
        if ('nfc' in navigator) {
            navigator.nfc.watch(function (message) {
                parent.initList(message.records[0].data);
            }, {mode: 'any'}).then(() => console.log("Added a watch."))
                             .catch(err => console.log("Adding watch failed: " + err.name));
        } else {
            // toast.error('NFC API not supported.');
            console.log("NFC API not supported");
        }
    }

    componentDidMount() {    
        if (this.props.location.search !== "") {
            this.setState({loggedIn:true});
            this.initList(this.props.location.search.replace("?token=", ""));
        }
    }

    initList(token) {    
        console.log(token);    
        this.setState({isLoading:true});
        const parent = this;
        axios.post(url + '/api/v1/authenticate/token', token, {
            headers: { 'Content-Type': 'text/plain' }
        }).then(response => {                  
            parent.setState({
                user: response.data
            });
            this.setState({isLoading:false});
        }).catch(error => {
            this.setState({isLoading:false});
            toast.error("De pas wordt niet herkend. Probeer het opnieuw.");
        });
    }

    render() {         
        
        // redirects
        if (this.state.redirectToHomePage) { return (<Redirect to="/"/>); }
        
        let detailPage;
        if (this.state.user === null) {            
            if (!this.state.isLoading) {
                detailPage = (<h1 className="scan-nfc-title">Scan uw kaart!</h1>);   
            }
        } else { 
            detailPage = (<List user={this.state.user} logout={this.logout.bind(this)}/>);
        }         
        return (
           <div className="nfc-page">               
                {detailPage}
                <ToastContainer />
                <div className="loader">
                    <DotLoader loading={this.state.isLoading} sizeUnit={"px"} size={200} color="grey" />
                </div>
           </div>
        )
    }

    logout() {        
        this.setState({user:null, redirectToHomePage:this.state.loggedIn});
    }
}

export default Scan;
