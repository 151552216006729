import axios from 'axios';
import setAuthorizationToken from "../utils/setAuthorizationToken";
import jwt from 'jsonwebtoken';
import { url } from '../utils/const';

//https://github.com/Remchi/reddice/tree/master/client
//https://www.youtube.com/watch?v=FyyPUIAe6kc

export function login(data) {
    return dispatch => {
        return axios.post(url + '/api/v1/authenticate', data).then(res => {
            const token = res.data;
            localStorage.setItem('jwtToken', token);
            setAuthorizationToken(token);
            dispatch(setCurrentUser(jwt.decode(token)));
        });
    }
}

export function setCurrentUser(user) {
    return {
        type: 'SET_CURRENT_USER',
        user
    };
}

export function logout() {
    return dispatch => {
        localStorage.removeItem('jwtToken');
        setAuthorizationToken(false);
        dispatch(setCurrentUser({}));
    }
}
