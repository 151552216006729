import React from 'react';
import CustomerRow from "./CustomerRow";
import Header from "./Header";
import axios from 'axios';
import { url } from '../../utils/const';
import {connect} from "react-redux";
import {fetchUser} from "../../actions/UserActions";

class Table extends React.Component {

    componentDidMount() {
        this.props.fetchUser();
    }

    componentWillMount() {
        this.setState({user: this.props.user.user})
        this.setState({updatedHolidaySaldo: false});
        this.setState({customers: this.props.customers});
    }

    render() {

        let week= this.props.week,
            year= this.props.year;

        let day = new Date(this.firstDayOfWeek(week, year));

        const days = [];
        for (let i = 0; i < 7; i++) {
            days.push(new Date(day));
            day.setDate(day.getDate() + 1)
        }


        
        const stats = this.getStats(this.props.customers, days);

        //
        // search feature
        //
        const rows = this.props.customers.map(customer => {
            
            if (customer.name.toLowerCase().includes(this.props.search.toLowerCase())) {
                return ( <CustomerRow showWeekend={this.props.showWeekend}
                                      key={customer.name}
                                      updateHolidaySaldo={this.updateHolidaySaldo.bind(this)}
                                      customer={customer}
                                      stats={stats.customers[customer.id]}
                                      days={days}/> )
            } else if (this.props.search != null) {
                for (let i = 0; i < customer.projects.length; i++) {
                    if (customer.projects[i].name.toLowerCase().includes(this.props.search.toLowerCase())
                        ||  (customer.projects[i].category != null && customer.projects[i].category.toLowerCase().includes(this.props.search.toLowerCase()))) {
                        return ( <CustomerRow showWeekend={this.props.showWeekend}
                                              key={customer.name}
                                              updateHolidaySaldo={this.updateHolidaySaldo.bind(this)}
                                              customer={customer}
                                              stats={stats.customers[customer.id]}
                                              days={days}
                                              searchQuery={this.props.search}/> )
                    }
                }
            }
            return null;
        });

        const monthStrings = ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"];        
        let sheetMonth = days[0].getMonth();
        let sheetYear = days[0].getFullYear();
        let pdfDownload1 = (
            <div>
                <span className="small clickable hover-underline-animation"
                     onClick={this.downloadPdf.bind(this)}
                     data-month={sheetMonth}
                     data-year={sheetYear}
                     data-per-customer={true}>
                    Download maandsheet van {monthStrings[sheetMonth]} {sheetYear}
                </span>
                &nbsp;
                <span className="small clickable hover-underline-animation"
                      onClick={this.downloadPdf.bind(this)}
                      data-month={sheetMonth}
                      data-year={sheetYear}
                      data-per-customer={false}>
                    (per klant)
                </span>
            </div>
        );
    
        let pdfDownload2 = (<span></span>);
        if (days[0].getMonth() !== days[days.length-1].getMonth()) {
            sheetMonth = days[days.length -1].getMonth();
            sheetYear = days[days.length -1].getFullYear();
            pdfDownload2 = (
                <div>
                    <span className="small clickable hover-underline-animation"
                         onClick={this.downloadPdf.bind(this)}
                         data-month={sheetMonth}
                         data-year={sheetYear}
                         data-per-customer={true}>
                        Download maandsheet van {monthStrings[sheetMonth]} {sheetYear}
                    </span>
                    &nbsp;
                    <span className="small clickable hover-underline-animation"
                          onClick={this.downloadPdf.bind(this)}
                          data-month={sheetMonth}
                          data-year={sheetYear}
                          data-per-customer={false}>
                    (per klant)
                    </span>
                </div>
            );
        }


        //
        // renders the table
        //
        return (
            <div className="registration-table clear">
                <Header showWeekend={this.props.showWeekend}
                        user={this.props.user.user}
                        week={week}
                        fetchUser={this.fetchUser.bind(this)}
                        updatedHolidaySaldo={this.state.updatedHolidaySaldo}
                        days={stats.days}
                        total={stats.total}/>
                {rows}   
                
                <div className="m-t-sm m-l-xs">
                    {pdfDownload1}
                    {pdfDownload2}
                    <br/>
                </div>             
            </div>
        )
    }

    updateHolidaySaldo () {
        this.setState({updatedHolidaySaldo: true});
    }

    fetchUser() {
        this.props.fetchUser();
        this.setState({updatedHolidaySaldo: false});
    }

    downloadPdf(event) {
        let month = parseInt(event.currentTarget.getAttribute('data-month')) + 1;
        let year = event.currentTarget.getAttribute('data-year');
        let perCustomer = event.currentTarget.getAttribute('data-per-customer');
        axios({
            url: url +  "/api/v1/secure/month-sheet?month=" + month + "&year=" + year + "&perCustomer=" + perCustomer,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.pdf');
            document.body.appendChild(link);
            link.click();
      });
    }

    static datesAreEqual(date1, date2) {
        let sameDay = date1.getDate() === date2.getDate(),
            sameMonth = date1.getMonth() === date2.getMonth(),
            sameYear =  date1.getFullYear() === date2.getFullYear();
        return sameDay && sameMonth && sameYear;
    }

    /**
     * jeez this code smells; just ignore this part
     */
    getStats(customers, days) {
        
        const stats = {};
        stats.total = 0;        
        stats.days = [{ total:0, date:days[0] },{ total:0, date:days[1] },{ total:0, date:days[2] }, { total:0, date:days[3] },
                      { total:0, date:days[4] },{ total:0, date:days[5] },{ total:0, date:days[6] }];
        stats.customers = {};

        for (let i = 0; i < customers.length; i++) {

            const customer = customers[i];
            stats.customers[customer.id] = {};            
            stats.customers[customer.id].projects = {};
            stats.customers[customer.id].total = 0;

            for (let j = 0; j < customer.projects.length; j++) {

                const project = customer.projects[j];
                stats.customers[customer.id].projects[project.id] = {};
                stats.customers[customer.id].projects[project.id].total = 0;
                
                for (let k = 0; k < project.workSessions.length; k++) {

                    const workSession = project.workSessions[k];
                    const workedDate = new Date(workSession.date);

                    stats.customers[customer.id].total += workSession.numberOfHours;
                    stats.customers[customer.id].projects[project.id].total += workSession.numberOfHours; 
                    stats.total += workSession.numberOfHours;

                    for (let l = 0; l < days.length;l++) {
                        const day = days[l];         
                        
                        if (Table.datesAreEqual(workedDate, day)) {                        
                            stats.days[l].total += workSession.numberOfHours;
                        }
                    }
                }
            }
        }
        
        return stats;
    }


    /* -------------------- for getting a date from a year and week -------------------- */


    /* For a given date, get the ISO week number
     *
     * Based on information at:
     *
     *    http://www.merlyn.demon.co.uk/weekcalc.htm#WNR
     *
     * Algorithm is to find nearest thursday, it's year
     * is the year of the week number. Then get weeks
     * between that date and the first day of that year.
     *
     * Note that dates in one year can be weeks of previous
     * or next year, overlap is up to 3 days.
     *
     * e.g. 2014/12/29 is Monday in week  1 of 2015
     *      2012/1/1   is Sunday in week 52 of 2011
     */
    static getWeekNumber(d) {
        // Copy date so don't modify original
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
        // Get first day of year
        let yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
        // Calculate full weeks to nearest Thursday
        let weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
        // Return array of year and week number
        return [d.getUTCFullYear(), weekNo];
    }

    firstDayOfWeek(week, year) {

        if (year==null) {
            year = (new Date()).getFullYear();
        }

        let date       = Table.firstWeekOfYear(year),
            weekTime   = Table.weeksToMilliseconds(week),
            targetTime = date.getTime() + weekTime;

        return date.setTime(targetTime);

    }

    static weeksToMilliseconds(weeks) {
        return 1000 * 60 * 60 * 24 * 7 * (weeks - 1);
    }

    static firstWeekOfYear(year) {
        let date = new Date();
        date = Table.firstDayOfYear(date,year);
        date = Table.firstWeekday(date);
        return date;
    }

    static firstDayOfYear(date, year) {
        date.setYear(year);
        date.setDate(1);
        date.setMonth(0);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
    }

    /**
     * Sets the given date as the first day of week of the first week of year.
     */
    static firstWeekday(firstOfJanuaryDate) {
        let FIRST_DAY_OF_WEEK = 1; // Monday, according to iso8601
        let WEEK_LENGTH = 7; // 7 days per week
        let day = firstOfJanuaryDate.getDay();
        day = (day === 0) ? 7 : day; // make the days monday-sunday equals to 1-7 instead of 0-6
        let dayOffset=-day+FIRST_DAY_OF_WEEK; // dayOffset will correct the date in order to get a Monday
        if (WEEK_LENGTH-day+1<4) {
            // the current week has not the minimum 4 days required by iso 8601 => add one week
            dayOffset += WEEK_LENGTH;
        }
        return new Date(firstOfJanuaryDate.getTime()+dayOffset*24*60*60*1000);
    }

    static validateYear(yearParam) {
        yearParam = parseInt(yearParam, 10);
        if (yearParam > 0 && 999999) {
            return yearParam;
        } else {
            return null; // todo redirect to "/"
        }
    }

    static validateWeek(weekParam) {
        weekParam = parseInt(weekParam, 10);
        if (weekParam > 0 && 52) {
            return weekParam;
        } else {
            return null; // todo redirect to "/"
        }
    }
}

function mapStateToProps(state) {
    return {
        auth: <state className="auth"/>,
        user: state.user
    };
}

export default connect(mapStateToProps, { fetchUser })(Table);
