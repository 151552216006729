import React from 'react';
import Header from '../Header/Header';
import Table from '../Table/Table';
import Navigation from '../Navigation/Navigation';
import {logout} from "../../actions/AuthActions";
import { connect } from 'react-redux';
import {fetchCustomers, fetchCustomerType} from "../../actions/CustomerActions";

class Layout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.table = React.createRef();
        this.fetchCustomerType = this.props.location.pathname === '/holiday-overview' ? fetchCustomerType.HOLYDAY_OVERVIEW : fetchCustomerType.WORKSESSION_OVERVIEW;

        // not al years have 52 weeks; some have 53, these include the following
        // id be honored if this software will still be used in the year 2100 and later
        // but ill surely be dead then, it wont be my problem anyway  ¯\_(ツ)_/¯
        this.yearsWithFiftyThreeWeeks = [2009, 2015, 2020, 2026, 2032, 2043,
                                         2048, 2053, 2060, 2065, 2071, 2076,
                                         2082, 2088,2093, 2099]
    }

    componentWillMount() {
        this.setState({showWeekend: false});

        let weekParam = this.props.match.params.week,
            yearParam = this.props.match.params.year;

        weekParam = Table.validateWeek(weekParam);
        yearParam = Table.validateYear(yearParam);


        let week, year;
        if (weekParam == null && yearParam == null) {
            const result = Table.getWeekNumber(new Date());
            year = result[0];
            week = result[1];
        } else if (weekParam != null && yearParam == null) {
            const date = new Date();
            year = date.getFullYear();
            week = weekParam;
        } else {
            week = weekParam;
            year = yearParam;
        }

        this.setState( { query: {week: week, year: year}, search: "" } );
        this.props.fetchCustomers(week, year, this.fetchCustomerType);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.query.year !== this.state.query.year || prevState.query.week!== this.state.query.week) {
            this.props.fetchCustomers(this.state.query.week, this.state.query.year, this.fetchCustomerType);
        }
    }

    toggleWeekend() {
        let currentShowWeekendState = this.state.showWeekend;
        this.setState({showWeekend: !currentShowWeekendState});
    }

    search(event) {
        this.setState({search: event.currentTarget.value})
    }

    // Q:  is this robust? Do years ALWAYS have ONLY 52 weeks?
    // A:  The years in array this.yearsWithFiftyThreeWeeks do not
    previousWeek() {
        this.setState((prevState) => {
            let week, year;
            if (prevState.query.week === 1) {
                year = prevState.query.year - 1;
                if (this.yearsWithFiftyThreeWeeks.includes(year) && prevState.query.week !== 53) {
                    week = 53;
                } else {
                    week = 52;
                }
            } else {
                week = prevState.query.week - 1;
                year = prevState.query.year;
            }

            return { query: {week: week, year: year} };
        });
    }

    // Q:  is this robust? Do years ALWAYS have ONLY 52 weeks?
    // A:  The years in array this.yearsWithFiftyThreeWeeks do not
    nextWeek() {
        this.setState((prevState) => {
            let week, year;
            if (prevState.query.week >= 52) {
                if (this.yearsWithFiftyThreeWeeks.includes(prevState.query.year) && prevState.query.week !== 53) {
                    week = 53;
                    year = prevState.query.year;
                } else {
                    week = 1;
                    year = prevState.query.year + 1;
                }

            } else {
                week = prevState.query.week + 1;
                year = prevState.query.year;
            }
            return { query: {week: week, year:year} };
        });
    }

    now() {
        this.setState((prevState) => {
            const result = Table.getWeekNumber(new Date());
            return { query: {week: result[1], year:result[0]} };
        });
    }

    datePick(date) {
        this.setState((prevState) => {
            const result = Table.getWeekNumber(date);
            return { query: {week: result[1], year:result[0]} };
        });
    }

    render() {

        // when the user exists anymore, customers will be "", so the current user must be logged out.        
        if (this.props.customers.customers === "") {                        
            this.props.logout();
            window.location.reload();
        }

        return (
            <div>
                <Header location={this.props.location}
                        search={this.search.bind(this)}
                        toggleWeekend={this.toggleWeekend.bind(this)}
                        week={this.state.query.week}
                        year={this.state.query.year}
                        previousWeek={this.previousWeek.bind(this)}
                        nextWeek={this.nextWeek.bind(this)} />

                <nav className="m-t-xs">
                    <div className="container">
                        <div className="navigation-container">
                            <Navigation toggleWeekend={this.toggleWeekend.bind(this)}
                                        week={this.state.query.week}
                                        year={this.state.query.year}
                                        previousWeek={this.previousWeek.bind(this)}
                                        nextWeek={this.nextWeek.bind(this)}
                                        now={this.now.bind(this)}
                                        datePick={this.datePick.bind(this)}/>
                        </div>
                        <div className="clearFix"/>
                    </div>
                </nav>

                <main className="main-content m-t-xs">
                    <div className="container">
                        <div className="card">
                            <Table customers={this.props.customers.customers}
                                   ref={this.table}
                                   key={"table-" + this.state.query.week + "-" + this.state.query.year}
                                   search={this.state.search}
                                   showWeekend={this.state.showWeekend}
                                   week={this.state.query.week}
                                   year={this.state.query.year}/>
                        </div>
                    </div>
                </main>                
            </div>
        )
    }
}

const mapStateToProps = state => ({
    customers: state.customers
});

export default connect(mapStateToProps, { fetchCustomers, logout })(Layout);
