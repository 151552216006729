import axios from "axios/index";
import { url } from '../utils/const';


export const fetchCustomers = (week, year, type) => dispatch => {
    const endpoint = type === fetchCustomerType.HOLYDAY_OVERVIEW ? '/holidays/overview' : '/customers';
    return axios.get(url + '/api/v1/secure' + endpoint , { params: {week: week, year: year} }).then(res => {
        dispatch({type: "FETCH_CUSTOMERS", payload: res.data })
    });
};
export const fetchCustomerType = { HOLYDAY_OVERVIEW: "Holiday overview", WORKSESSION_OVERVIEW: "WorkSession overview"};

export const addWorkSession = (data) => dispatch => {
    return new Promise((accept, reject) => {
        const loc = url + '/api/v1/secure/customer/'+data.customer.id+'/project/'+data.project.id+'/work-session';
        const workSession = { numberOfHours: data.time, date: data.date, description: data.description };
        return axios.put(loc, workSession).then(() => {
            dispatch({type: "ADD_WORK_SESSION", payload: data })
            accept();
        },() => reject());
    });
};
