import React from 'react';
import ProjectRow from "./ProjectRow";

class CustomerRow extends React.Component {

    render() {
        const query = this.props.searchQuery;
        let name = this.props.customer.name;
        let currentCategory = null;
        const stats = this.props.stats;
        
        const rows = this.props.customer.projects.map(project => {

            if (query != null) {   

                if  (project.name.toLowerCase().includes(query.toLowerCase())  
                        || (project.category != null && project.category.toLowerCase().includes(query.toLowerCase()))) {

                    let displayCategoryTitle = false;
                    if (project.category !== currentCategory) {
                        displayCategoryTitle = true;
                        currentCategory = project.category;                
                    }

                    return <ProjectRow key={this.props.customer.name + project.name + project.id}
                                       project={project}
                                       updateHolidaySaldo={this.props.updateHolidaySaldo}
                                       days={this.props.days}
                                       customer={this.props.customer}
                                       stats={stats.projects[project.id]}
                                       displayCategoryTitle={displayCategoryTitle}/>;
                } else return null // when a search query was entered AND it does not match: return null
            } else {

                let displayCategoryTitle = false;
                if (project.category !== currentCategory) {
                    displayCategoryTitle = true;
                    currentCategory = project.category;                
                }

                return <ProjectRow key={this.props.customer.name + project.name + project.id}
                                   project={project}
                                   updateHolidaySaldo={this.props.updateHolidaySaldo}
                                   days={this.props.days}
                                   customer={this.props.customer}
                                   stats={stats.projects[project.id]}
                                   displayCategoryTitle={displayCategoryTitle}/>;
            }
        });

        let statSpan
        let total = "";
        if (stats.total !== 0) {
            total = " (" + stats.total + ")";
            // name = name +"<span>"+ stats.total + "</span>";
            // statSpan = (<span class="float-right strong p-r-xs underline">{stats.total}</span>)
        }

        return (
            <div className={this.props.showWeekend ? "open customer-row" : "customer-row"}>
                <div className="customer-row-header" data-starred="false" title={name + total}>{name}{statSpan}</div>
                {rows}
            </div>
        )
    }
}

export default CustomerRow;
