import React from 'react'
import {logout} from "../../actions/AuthActions";
import {fetchUser} from "../../actions/UserActions";
import {connect} from "react-redux";
import { Redirect } from "react-router-dom";
import {url } from '../../utils/const';


class Menu extends React.Component {

    constructor(props) {
        super(props);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
        this.state = {};
    }

    componentDidMount() {
        this.setState({open: false});
        this.props.fetchUser();
    }

    logout(e) {
        // remove eventListener that closes the menu when clicked outside of it
        document.removeEventListener('click', this.handleOutsideClick, false);
        e.preventDefault();
        this.props.logout();
    }

    //https://codepen.io/graubnla/pen/EgdgZm
    toggleMenu() {
        if (!this.state.open) { // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }
        let currentState = this.state.open;
        this.setState({open: !currentState});
    }

    handleOutsideClick(e) {
        if (this.node.contains(e.target)) { // ignore clicks on the component itself
            return;
        }
        this.toggleMenu();
    }

    holidays() {
        document.removeEventListener('click', this.handleOutsideClick, false);
        this.setState({redirect: "holidays"})
    }

    holidayOverview() {
        document.removeEventListener('click', this.handleOutsideClick, false);
        this.setState({redirect: "holiday-overview"})
    }

    changePassword() {
        document.removeEventListener('click', this.handleOutsideClick, false);
        this.setState({redirect: "change-password"})
    }

    hours() {
        document.removeEventListener('click', this.handleOutsideClick, false);
        this.setState({redirect: "/"})
    }

    clock() {        
        if (this.props.user.user != null) {
            document.removeEventListener('click', this.handleOutsideClick, false);
            this.setState({redirect: "Scan"})
        }
    }
    render() {

        if (this.state.redirect != null && this.state.redirect) {
            if (this.state.redirect === "holidays") {
                return (<Redirect to="/holidays" />);
            }
            if (this.state.redirect === "holiday-overview") {
                return (<Redirect to="/holiday-overview" />);
            }
            if (this.state.redirect === "change-password") {
                return (<Redirect to="/change-password" />);
            }
            if (this.state.redirect === "/") {
                return (<Redirect to="/" />);
            }
            if (this.state.redirect === "Scan") {                                
                const s = "scan?token="+this.props.user.user.authenticationToken;
                return (<Redirect to={s}/>);
            }
        }

        let clock = (<li title="Navigeert naar de uren klokken-pagina"><span className="block clickable" onClick={this.clock.bind(this)}><i className="fal fa-stopwatch inline-block m-r-sm m-l-sm"/><span>Uren klokken</span></span></li>)
        let hours = (<li title="Navigeert naar de uren schrijven-pagina"><span className="block clickable" onClick={this.hours.bind(this)}><i className="fal fa-stopwatch inline-block m-r-sm m-l-sm"/><span>Uren schrijven</span></span></li>)
        let changePassword = (<li title="Navigeert naar de wachtwoord wijzigen-pagina"><span className="block clickable" onClick={this.changePassword.bind(this)}><i className="fal fa-key inline-block m-r-sm m-l-sm"/><span>Wijzig wachtwoord</span></span></li>)
        let holidays = (<li title="Navigeert naar de vakantie-aanvraagpagina"><span className="block clickable" onClick={this.holidays.bind(this)}><i className="fal fa-sun icon inline-block m-r-sm m-l-sm"/><span>Mijn vakanties</span></span></li>)
        let holidayOverview = (<li title="Navigeert naar de vakantiespagina"><span className="block clickable" onClick={this.holidayOverview.bind(this)}><i className="fal fa-map icon inline-block m-r-sm m-l-sm"/><span>Alle vakanties</span></span></li>)
        let controlpanel = (<li title="Navigeert naar het beheerpaneel"><a href={ url + '/customers' } className="block"><i className="fal fa-table icon inline-block m-r-sm m-l-sm"/><span>Beheer</span></a></li>)
        let logout = (<li title="Logt uit het systeem"><span className="block clickable" onClick={this.logout.bind(this)}><i className="fal fa-sign-out inline-block m-r-sm m-l-sm"/><span>Logout</span></span></li>)
        let loc = this.props.location.pathname;
        let manager = this.props.user.user != null && this.props.user.user.isManager;

        // Managers and employees see a different MenuItem. Managers see a link to the admin panel. Employees see a link to the holiday request page.
        if (!manager)                   controlpanel = '';
        if (loc === "/")                hours = '';
        if (loc === "/change-password") changePassword = '';
        if (loc === "/holidays")        holidays = '';
        if (loc === "/holiday-overview") holidayOverview = '';


        return (
            <div className="full-height float-right relative" ref={node => { this.node = node; }}>
                <div className="float-right full-height m-r submenu-trigger-container">
                    <span title="Opent/sluit het hoofdmenu" className="submenu-trigger" id="submenu-trigger" onClick={this.toggleMenu.bind(this)}><i className="fas fa-ellipsis-v white"/></span>
                </div>
                <div className={ this.state.open ? "submenu-container" : "closed submenu-container"}>
                    <div>
                    <ul className={"card submenu-menu"}>
                        {clock}
                        {hours}
                        {holidays}
                        {holidayOverview}
                        {changePassword}
                        {controlpanel}
                        {logout}
                    </ul>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        auth: <state className="auth"/>,
        user: state.user
    };
}

export default connect(mapStateToProps, { logout, fetchUser })(Menu);
