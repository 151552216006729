import React from 'react';
import HeaderItem from "./HeaderItem";

class Header extends React.Component {

    render() {
        const user = this.props.user;
        const total = this.props.total
        const cls = user != null && user.workingHoursPerWeek <= total ? "success-color" : "error-color"

        let offTime, pendingOffTime, color;
        if (user != null) {
            ({offTime, pendingOffTime} = user);
            color = offTime < pendingOffTime ? "red" : "black"
            if (this.props.updatedHolidaySaldo) {
                pendingOffTime = (<span onClick={this.updateOffTime.bind(this)}
                                        style={{textDecoration: "underline", cursor: "pointer"}}>?</span>);
            }
        }

        let headerItems = this.props.days.map(date => {
            return ( <HeaderItem key={date.date} date={date}/> )
        });
        return (
            <div className={this.props.showWeekend ? "open registration-table-header clear" : "registration-table-header clear" }>
                <div className="offset remove-on-mobile table-details">
                    <div className="float-left" style={{"height": "initial", "width": "125px"}}>
                        <div className="strong"><span className="head">Week:</span> <span>{this.props.week}</span></div>
                        <div className="strong" title="Geboekte uren"><span className="head">Totaal:</span> <span className={cls}>{total}</span></div>
                    </div>
                    <div className="col-6 float-left " style={{"height": "initial", "width": "150px"}}>
                        <div className="strong" title="Vakantiedagen saldo, openstaande aanvragen zijn hierin nog niet verwerkt."><span className="head" style={{"width": "120px"}}>Vakantiedagen:</span> <span style={{color: color}}>{offTime}</span></div>
                        <div className="strong" title="Aangevraagde vakantiedagen die nog niet goed- of afgekeurd zijn."><span className="head" style={{"width": "120px"}}>In aanvraag:</span> <span style={{color: color}}>{pendingOffTime}</span></div>
                    </div>
                </div>
                {headerItems}
            </div>
        )
    }
    updateOffTime() { this.props.fetchUser(); }

    updatedHolidaySaldo() {
    }

}
export default Header;
