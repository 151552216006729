import Table from "../components/Table/Table";

const initialState = {
    customers: []
};

export default function(state = initialState, action) {
    switch (action.type) {

        case "FETCH_CUSTOMERS":

            return {
                ...state,
                customers: action.payload
            };

        case "ADD_WORK_SESSION" :

            let newState = {...state};

            let customerIndex = 0;
            for (let i = 0; i< newState.customers.length; i++) {
                if (newState.customers[i].id === action.payload.customer.id) {
                    customerIndex = i;
                    break;
                }
            }

            let projectIndex = 0;
            for (let i = 0; i < newState.customers[customerIndex].projects.length; i++) {
                if (newState.customers[customerIndex].projects[i].id === action.payload.project.id) {
                    projectIndex = i;
                    break;
                }
            }

            let workSessionIndex = null;
            for (let i = 0; i < newState.customers[customerIndex].projects[projectIndex].workSessions.length; i++) {
                let date1 = new Date(newState.customers[customerIndex].projects[projectIndex].workSessions[i].date);
                let date2 = new Date(action.payload.date);
                if (Table.datesAreEqual(date1, date2)) {
                    workSessionIndex = i;
                    break;
                }
            }

            const workSessionEntry = {
                numberOfHours: action.payload.time,
                description: action.payload.description,
                date: action.payload.date
            };

            if (workSessionIndex == null) {
                if (workSessionEntry.numberOfHours !== 0) {
                    newState.customers[customerIndex].projects[projectIndex].workSessions.push(workSessionEntry)
                }
            } else {
                newState.customers[customerIndex].projects[projectIndex].workSessions[workSessionIndex] = workSessionEntry;
            }

            return newState;

        default: return state;
    }
}
