import React from 'react'

class Project extends React.Component {

    render() {
        const { project, showCategoryHeader, showCustomerHeader } = this.props;
        const customer = showCustomerHeader ? (
                    <div>
                        <div className="clearFix"></div>
                        <div className="customer-title">{project.customer.name}</div>
                        <div className="clearFix"></div>
                    </div>
        ) : '';
        const category = showCategoryHeader ? (
                    <div>
                        <div className="clearFix"></div>
                        <div className="category-title">{project.category}</div>     
                        <div className="clearFix"></div>
                    </div>               
        ) : '';
        return (
            <div className="nfc-project">         
                {customer}
                {category}
                <div onClick={this.activateProject.bind(this)} className="project-button">
                    {project.name}
                </div>
            </div>
        )
    }

    activateProject() {
        this.props.activateProject(this.props.project.id);
    }
}
export default Project;
